import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

/*
This component is used for blogpages in the right side on desktop and bottom side on mobile.
This component will display a single blogpost that it is fed.

@props: {
  data: {
    slug:string;
    title:string;
    featuredImage: gatsby queried image
  }
  mb: boolean -- used to determine wether the blogPost should have a margin bottom
}
*/

const LatestPost = ({ data, mb }) => {
  return (
    <article
      className={`${mb ? "lg:mb-lg" : ""} flex flex-col justify-between`}
    >
      <Link
        to={data.slug}
        className="font-semibold mb-2 block hover:text-black"
      >
        {data.title}
      </Link>
      <Link to={data.slug}>
        <Image
          fluid={data.featuredImage.childImageSharp.fluid}
          className="rounded h-30"
        />
      </Link>
    </article>
  )
}

export default LatestPost
