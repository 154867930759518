import React from "react"
import LatestPost from "./LatestPost"

/* 
This component will render latest posts in the sidebar of a blogpost.

@props: {
  posts: post[] which is basically the queried markdown file data.
}
*/

const LatestPostList = ({ posts }) => {
  const length = posts.length
  const renderList = posts.map((post, index) => {
    return (
      <LatestPost
        data={post.frontmatter}
        readTime={post.timeToRead}
        key={index}
        mb={index !== length - 1}
      />
    )
  })

  return (
    <section className="p-lg shadow-md bg-white rounded grid grid-cols-1 gap-lg sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 lg:block">
      {renderList}
    </section>
  )
}

export default LatestPostList
