import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/global/Layout"
import LatestPostList from "../components/blogComponents/LatestPostList"
import { FaRegClock, FaUser } from "react-icons/fa"
const Post = ({
  data: { markdownRemark: data, allMarkdownRemark: allData },
}) => {
  return (
    <Layout
      metaTitle={data.frontmatter.metaTitle}
      metaDescription={data.frontmatter.metaDescription}
      slug={data.frontmatter.slug}
    >
      <div className="container lg:grid-cols-3-1 grid mt-10 mb-20 gap-lg">
        <section className="bg-white rounded">
          <article>
            <Image
              fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
              className="rounded-tr rounded-tl"
            />
          </article>

          <article className="text-content">
            <div className="-mt-6 -mb-2 flex">
              <p className="flex items-center italic mr-5">
                <FaUser className="mr-2 fill-fontBlack" />{" "}
                {data.frontmatter.author}
              </p>
              <p className="flex items-center italic">
                <FaRegClock className="fill-fontBlack mr-2" />{" "}
                {data.timeToRead + 1} min. læsning
              </p>
            </div>
            <h1 className="text-content-h1-important">
              {data.frontmatter.title}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
          </article>
        </section>
        <aside className="sticky top-lg self-start">
          <h2>Seneste artikler</h2>
          <LatestPostList posts={allData.nodes} />
        </aside>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getPost($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        metaTitle
        metaDescription
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
      timeToRead
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { slug: { ne: $slug } } }
    ) {
      nodes {
        frontmatter {
          title
          slug
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        timeToRead
      }
    }
  }
`

export default Post
